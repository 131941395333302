<template>
  <section class="bg-[#f4f3ef] w-full">
    <header-banner></header-banner>
    <div class="bg-[#82afd8] border-t-[#82afd87f] border-b-[#82afd87f] border-y-8 h-24 border-solid">&nbsp;</div>
    <header class="w-full flex justify-between items-center p-6 gap-4">
      <h6 class="text-2xl">Produtos</h6>
      <input
        @keyup="searchProduct($event)"
        id="home-search-input"
        class="border-none p-4 w-full rounded"
        placeholder="Buscar…"
        type="text"
      />
    </header>
    <div v-for="category in arr_data" :key="category.id">
      <div class="w-full product-section">
        <button class="w-full text-left border-l-[#82afd87f] border-l-[12px] h-24 flex border-solid items-center home-collapsible-product transition-all hover:bg-[#fcfcfc]">
          <div class="w-full flex flex-wrap content-center p-4">
            <span class="w-full">{{ category.title }}</span>
            <p class="w-full">{{ category.description }}</p>
          </div>
        </button>
        <div class="home-content-products">
          <a
            v-for="product in category.products" :key="product.id"
            @click="shopProduct(product.id)"
            :data-id="product.id"
            class="home-content-item w-full text-left h-28 flex items-center py-4 px-6 transition-all hover:bg-[#fcfcfc] cursor-pointer"
          >
            <div class="w-full flex flex-wrap content-center">
              <p class="w-full text-black">{{ product.title }}</p>
              <p class="w-full">{{ product.description }}</p>
              <p class="w-full">R$ {{ parseFloat(product.price).toFixed(2) }}</p>
            </div>
            <div class="flex flex-wrap content-center justify-center">
              <img class="rounded w-20 h-20 object-cover" :src="`https://monteseubolo.com/admin/storage/products_images/${product.id}/${product.cover_image}`">
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import HeaderBanner from './HeaderBanner.vue';

export default {
  name: 'HomeIndex',
  components: {
    HeaderBanner
  },
  data() {
    return {
      arr_data: []
    }
  },
  mounted() {
    this.fetchProducts().then(() => {
      this.triggerAnimation()
    });
  },
  methods: {
    async fetchProducts() {
      await axios.get("/api/products")
      .then(response => {
        this.arr_data = response.data;
      });
    },
    shopProduct(product_id) {
      this.$router.push({
          name: 'ProductView',
          state: {
            product_id_to_view: product_id,
          },
        })
    },
    triggerAnimation() {
      let elements = document.getElementsByClassName('home-collapsible-product');

      for (let item of elements) {
        item.addEventListener("click", function () {
          if (this.classList.contains("active")) {
            this.parentElement.classList.remove("active-section");
            this.classList.remove("active");
            this.nextElementSibling.style.maxHeight = null;
          } else {
            for (let item of elements) {
              item.parentElement.classList.remove("active-section");
              item.classList.remove("active");
              item.nextElementSibling.style.maxHeight = null;
            }
            this.parentElement.classList.add("active-section");
            this.classList.add("active");
            this.nextElementSibling.style.maxHeight = this.nextElementSibling.scrollHeight + "px";
          }
        });
      }
    },
    searchProduct(event) {
      let elements = document.getElementsByClassName('home-content-products');
      if (event.target.value) {
        for (let element of elements) {
          element.parentElement.style.display = "none";
          element.parentElement.classList.remove("active-section");
          element.parentElement.children[0].classList.remove("active");
          element.style.maxHeight = null;
          for (let button of element.getElementsByTagName("a")) {
            let arr_filtered = [].filter.call(button.getElementsByTagName("p"), item => !item.innerHTML.replace(/[^a-zA-Z\s]/g, "").toLowerCase().includes(event.target.value.replace(/[^a-zA-Z\s]/g, "").toLowerCase()));
            if (arr_filtered.length === button.getElementsByTagName("p").length) {
              button.style.display = "none";
            } else {
              button.style.display = "flex";
              element.parentElement.style.display = "block";
              element.parentElement.classList.add("active-section");
              element.parentElement.children[0].classList.add("active");
              element.style.maxHeight = element.scrollHeight + "px";
            }
          }
        }
      } else {
        for (let element of elements) {
          for (let button of element.getElementsByTagName("a")) {
            button.style.display = "flex";
            element.parentElement.classList.remove("active-section");
            element.parentElement.children[0].classList.remove("active");
            element.style.maxHeight = null;
          }
        }
      }
    }
  }
}
</script>